@import "../common";

.simple_jsx {
  @include flex_div($dir: column) {
    width: 250px;
  }
}

.simple_jsx_m {
  @include flex_div($dir: column) {
    width: 250px;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .close_btn {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 8px;
    right: 8px;
  }
  .text {
    @include common_text(10px);
  }
  .highlight {
    @include common_text(10px) {
      text-decoration: underline;
      color: $g-color-purpleishBlue;
    }
  }
}
