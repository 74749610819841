.react-datepicker {
  /* width: 220px; */
  /* border-radius: 10px; */
  border-color: #e8e8e8;
  /* border-color: #ff0000; */
  border-radius: 10px;
  /* background-color: transparent; */
}
.react-datepicker__header {
  background-color: transparent;
  border-bottom: unset;
  padding: unset;
  /* border-top-left-radius: 10px; */
  border-radius: 10px;
  border-top-right-radius: 10px;
}
.react-datepicker__day-names {
  background-color: #ffffff;
}

.react-datepicker__day-name {
  color: #888f99;
}

.react-datepicker__month {
  /* background-color: #ffffff; */
  padding: 10px;
  margin-top: 0px;
  /* padding-top: 0px; */
}

.react-datepicker__day--disabled {
  color: #cccccc !important;
}

.react-datepicker__triangle {
  visibility: hidden;
}

.react-datepicker__day {
  color: #888f99;
  margin: 2px;
}

.react-datepicker__day--selected {
  background-color: #4735ff;
  color: #ffffff;
}

.react-datepicker__day--outside-month {
  color: #e8e8e8;
}

.react-datepicker__day--keyboard-selected {
  background-color: #4735ff;
  color: #ffffff;
}
