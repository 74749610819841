@import "../common";

$betweenSection: 24px;

.payout_jsx {
  @include flex_div($dir: column) {
    width: 582px;
  }
  padding-top: 30px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;

  .dlg_title {
    color: $g-color-black;
    font-size: 22px;
    font-weight: bold;
    align-self: center;
    margin-bottom: 30px;
  }

  .dlg_sub_title {
    font-weight: bold;
    align-self: center;
    font-size: 14px;
  }

  .balance {
    align-self: center;
    color: $g-color-purpleishBlue;
    font-size: 18px;
    font-weight: bold;
    margin-top: 4px;
    margin-bottom: 30px;
  }

  .common_section_title {
    @include flex_div($dir: row);
    font-size: 14px;
    margin-bottom: 14px;
    .main {
      font-weight: bold;
    }
    .sub {
      font-weight: normal;
      margin-left: 3px;
    }
    .auth_status {
      color: $g-color-purpleishBlue;
      margin-left: auto;
    }
    .auth_status_img {
      width: 20px;
      height: 20px;
    }
  }

  // 보내는 금액
  .section_import {
    .amount_input {
      @include common_input_box($width: 330px, $height: 44px);
    }

    .amount_input_focus {
      @include common_input_box_focus($width: 330px, $height: 44px);
    }

    .all_button {
      @include common_button_type_1($width: 160px, $height: 44px);
      margin-left: 12px;
      cursor: pointer;
      &:hover {
        border-color: $g-color-purpleishBlue;
      }
    }

    .validation {
      @include common_text($font-size: 12px) {
        color: $g-color-pastelRed;
      }
      margin-top: 6px;
    }
  }

  // 보내는 계좌
  .section_account {
    margin-top: $betweenSection;
    .box {
      @include common_box($width: 502px, $height: 68px) {
        flex-direction: row;
        align-items: center;
        padding-left: 14px;
        padding-right: 14px;
      }
      cursor: pointer;

      .img_plus {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
      .img_pappal_logo {
        width: 70px;
        height: 24px;
        
      }
      .vline {
        width: 1px;
        height: 50px;
        background-color: $g-color-veryLightGrey2;
        margin-left: 14px;
        margin-right: 14px;
      }
      .paypal_account {
        @include common_text($font-size: 14px) {
          font-weight: bold;
          user-select: none;
        }
      }
      .edit_btn {
        $size: 38px;
        width: $size;
        height: $size;
        border-style: solid;
        border-width: 1px;
        border-color: $g-color-line;
        border-radius: $size/2;
        margin-left: auto;
        cursor: pointer;
        img {
          width: $size;
          height: $size;
        }
      }
    }
  }

  // 플랫폼 수수료
  .section_platform_fee {
    margin-top: $betweenSection;
    .box {
      @include common_box($width: 502px, $height: 68px) {
        flex-direction: row;
        align-items: center;
        padding-left: 14px;
      }
    }
    .radio_btn {
      flex: 1;
      align-self: stretch;
      align-items: center;
      @include flex_div($dir: row) {
        align-content: center;
        cursor: pointer;
      }
      .txt {
        @include common_text($font_size: 14px);
        margin-left: 4px;
      }
    }
    .kaikas_on_ic {
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background-color: #10c738;
      margin-right: 4px;
    }
    .kaikas_enablebtn_or_address {
      margin-right: 14px;
      @include flex_div($dir: row);
      position: absolute;
      justify-content: center;
      align-self: center;
      align-items: center;
      right: 0px;
      width: 131px;
      height: 38px;
      font-size: 12px;

      border-style: solid;
      border-width: 1px;
      border-color: $g-color-line;
      border-radius: 19px;
    }
    .validation {
      @include common_text($font-size: 12px) {
        color: $g-color-pastelRed;
      }
      margin-top: 6px;
    }
  }

  // 페이팔 수수료
  .section_paypal_fee {
    margin-top: $betweenSection;
    .box {
      @include common_box($width: 502px, $height: 68px) {
        flex-direction: row;
        align-items: center;
        padding-left: 14px;
      }
    }
    .txt {
      @include common_text($font_size: 14px);
    }
    .tooltip {
      width: 20px;
      height: 20px;
      margin-left: 4px;
    }
  }

  // 신청안내
  .section_guide {
    margin-top: $betweenSection;
    .box {
      @include common_gray_box($width: 502px, $height: 96px) {
        align-items: center;
        padding-left: 14px;
        padding-right: 14px;
      }
      .guide {
        @include common_text($font-size: 13px) {
          line-height: 1.85;
          letter-spacing: -0.2px;
          color: $g-color-blueGrey;
        }
      }
    }
  }

  // 신청하기 버튼
  .apply_btn {
    $width: 320px;
    $height: 48px;
    align-self: center;
    margin-top: 40px;
    .apply_btn_disable {
      @include common_button_type_2($width: $width, $height: $height);
    }

    .apply_btn_enable {
      @include common_button_type_2_over($width: $width, $height: $height);
      cursor: pointer;
    }
  }
}

$betweenSection_m: 20px;

.payout_jsx_m {
  @include flex_div($dir: column) {
    width: 280px;
  }
  padding-top: 16px;
  padding-bottom: 20px;
  padding-left: 12px;
  padding-right: 12px;

  .dlg_title {
    color: $g-color-black;
    font-size: 14px;
    font-weight: bold;
    align-self: center;
    margin-bottom: 20px;
  }

  .dlg_sub_title {
    font-weight: bold;
    align-self: center;
    font-size: 12px;
  }

  .balance {
    align-self: center;
    color: $g-color-purpleishBlue;
    font-size: 14px;
    font-weight: bold;
    margin-top: 4px;
    margin-bottom: 20px;
  }

  .common_section_title {
    @include flex_div($dir: row);
    font-size: 12px;
    margin-bottom: 12px;
    .main {
      font-weight: bold;
    }
    .sub {
      font-weight: normal;
      margin-left: 3px;
      letter-spacing: -1px;
    }
    .auth_status {
      color: $g-color-purpleishBlue;
      margin-left: auto;
    }
    .auth_status_img {
      width: 20px;
      height: 20px;
    }
  }

  // 보내는 금액
  .section_import {
    .amount_input {
      @include common_input_box($width: 170px, $height: 38px, $font-size: 12px);
    }

    .amount_input_focus {
      @include common_input_box_focus($width: 170px, $height: 38px, $font-size: 12px);
    }

    .all_button {
      @include common_button_type_1($width: 80px, $height: 38px, $font-size: 11px);
      margin-left: 6px;
      cursor: pointer;
      &:hover {
        border-color: $g-color-purpleishBlue;
      }
    }

    .validation {
      @include common_text($font-size: 10px) {
        color: $g-color-pastelRed;
      }
      margin-top: 6px;
    }
  }

  // 보내는 계좌
  .section_account {
    margin-top: $betweenSection_m;
    .box {
      @include common_box($width: 256px, $height: undefined) {
        flex-direction: row;
        justify-content: flex-start;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 12px;
        padding-right: 12px;
      }
      cursor: pointer;

      .img_plus {
        width: 18px;
        height: 18px;
        margin-right: 2px;
      }
      .img_pappal_logo {
        width: 54px;
        height: 18px;
      }
      // .vline {
      //   width: 0px;
      //   height: 0px;
      //   background-color: $g-color-transparent;
      //   margin-left: 14px;
      //   margin-right: 14px;
      // }
      .paypal_account {
        @include common_text($font-size: 12px) {
          font-weight: bold;
          user-select: none;
        }
      }
      .edit_btn {
        $size: 28px;

        img {
          width: $size;
          height: $size;
          position: absolute;
          top: 8px;
          right: 8px;
          border-style: solid;
          border-width: 1px;
          border-color: $g-color-line;
          border-radius: $size/2;
          margin-left: auto;
          cursor: pointer;
        }
      }
    }
  }

  // 플랫폼 수수료
  .section_platform_fee {
    margin-top: $betweenSection_m;
    .box {
      @include common_box($width: 256px, $height: 80px) {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding-left: 14px;
        padding-top: 14px;
        padding-bottom: 14px;
      }
    }
    .radio_btn {
      align-self: stretch;
      align-items: center;
      @include flex_div($dir: row) {
        align-content: center;
        cursor: pointer;
      }
      .txt {
        @include common_text($font_size: 12px);
        margin-left: 4px;
      }
    }
    .kaikas_on_ic {
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background-color: #10c738;
      margin-right: 4px;
    }
    .kaikas_enablebtn_or_address {
      margin-right: 14px;
      @include flex_div($dir: row);
      position: absolute;
      justify-content: center;
      align-self: center;
      align-items: center;
      right: 0px;
      width: 98px;
      height: 28px;
      font-size: 10px;

      border-style: solid;
      border-width: 1px;
      border-color: $g-color-line;
      border-radius: 19px;
    }
    .validation {
      @include common_text($font-size: 10px) {
        color: $g-color-pastelRed;
      }
      margin-top: 6px;
    }
  }

  // 페이팔 수수료
  .section_paypal_fee {
    margin-top: $betweenSection_m;
    .box {
      @include common_box($width: 256px, $height: 38px) {
        flex-direction: row;
        align-items: center;
        padding-left: 14px;
      }
    }
    .txt {
      @include common_text($font_size: 12px);
    }
    .tooltip {
      width: 18px;
      height: 18px;
      margin-left: 4px;
    }
  }

  // 신청안내
  .section_guide {
    margin-top: $betweenSection_m;
    .box {
      @include common_gray_box($width: 256px, $height: 100px) {
        align-items: center;
        padding-left: 14px;
        padding-right: 14px;
      }
      .guide {
        @include common_text($font-size: 10px) {
          line-height: 1.85;
          color: $g-color-blueGrey;
        }
      }
    }
  }

  // 신청하기 버튼
  .apply_btn {
    $width: 256px;
    $height: 44px;
    align-self: center;
    margin-top: 20px;
    .apply_btn_disable {
      @include common_button_type_2($width: $width, $height: $height, $font-size: 12px);
    }

    .apply_btn_enable {
      @include common_button_type_2_over($width: $width, $height: $height, $font-size: 12px);
      cursor: pointer;
    }
  }
}
