/* SpoqaHanSans neo */
/* @import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css); */

/* SpoqaHanSans jp */
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-jp.css);

/* SpoqaHanSans jp local */
/* @font-face { font-family: 'SpoqaJP-Thin'; src: url(./res/fonts/SpoqaHanSans_JP/SpoqaHanSansJPThin.woff2) format('truetype'); }
@font-face { font-family: 'SpoqaJP-Light'; src: url(./res/fonts/SpoqaHanSans_JP/SpoqaHanSansJPLight.woff2) format('truetype'); }
@font-face { font-family: 'SpoqaJP-Regular'; src: url(./res/fonts/SpoqaHanSans_JP/SpoqaHanSansJPRegular.woff2) format('truetype'); }
@font-face { font-family: 'SpoqaJP-Bold'; src: url(./res/fonts/SpoqaHanSans_JP/SpoqaHanSansJPBold.woff2) format('truetype'); } */

/* Noto+Serif */
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: '#fcfcfc';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ReactVirtualized__Grid {
  outline: none;
}

.custom_modal_image {
  /* background-color: rgba(0, 0, 0, 0.5); */
}
