@import "../common";

$betweenSection: 24px;

.reg_paypal_jsx {
  @include flex_div($dir: column) {
    width: 420px;
    // height: 368px;
  }
  padding: 30px 30px 30px 30px;

  .dlg_title {
    @include flex_div($dir: row);
    color: $g-color-primary;
    align-self: center;
    align-items: center;

    img {
      width: 70px;
      height: 24px;
    }
    .txt {
      font-size: 16px;
      font-weight: bold;
      margin-left: 3px;
    }
  }

  .common_section_title {
    @include flex_div($dir: row);
    font-size: 14px;
    margin-bottom: 14px;
    .main {
      font-weight: bold;
    }
    .sub {
      font-weight: normal;
      margin-left: 3px;
    }
  }

  .close_btn {
    position: absolute;
    right: 14px;
    top: 14px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  // PayPal 이메일
  .section_paypal_email {
    margin-top: 30px;
    $width: 360px;
    $height: 44px;
    .email_input_unfocus {
      @include common_input_box($width: $width, $height: $height);
    }

    .email_input_focus {
      @include common_input_box_focus($width: $width, $height: $height);
    }

    .validation {
      @include common_text($font-size: 12px) {
        color: $g-color-pastelRed;
      }
      margin-top: 10px;
    }
  }

  // 이메일 인증하기
  .section_email_auth_code {
    margin-top: 24px;
    $width: 174px;
    $height: 44px;
    .auth_code_input_unfocus {
      @include common_input_box($width: $width, $height: $height);
    }

    .auth_code_input_focus {
      @include common_input_box_focus($width: $width, $height: $height);
    }

    // 인증 메일 발송 하기 버튼 / 인증하기 버튼
    .send_auth_btn_disable {
      @include common_button_type_2($width: $width, $height: $height);
      margin-left: auto;
    }

    .send_auth_btn_enable {
      @include common_button_type_2_over($width: $width, $height: $height);
      margin-left: auto;
      cursor: pointer;
    }

    // 인증완료
    .auth_complete {
      @include common_button_type_1($width: $width, $height: $height);
      margin-left: auto;
    }
  }

  // 인증메일 안내
  .section_email_auth_guide {
    @include flex_div($dir: column) {
      align-self: stretch;
    }
    .validation {
      margin-top: 12px;
      @include common_text($font-size: 12px) {
        color: $g-color-pastelRed;
      }
    }
    .box {
      margin-top: 12px;
      @include common_gray_box($width: undefined, $height: undefined) {
        align-self: stretch;
        padding: 14px 14px 14px 14px;
        flex-direction: column;
      }
      .txt {
        @include common_text($font-size: 12px) {
          color: $g-color-blueGrey;
          line-height: 1.83;
        }
      }
      .resend_text {
        @include common_text($font-size: 12px) {
          color: $g-color-purpleishBlue;
          cursor: pointer;
          line-height: 1.83;
          font-weight: bold;
        }
      }
    }
  }

  // 신청하기 버튼
  .reg_btn {
    $width: 160px;
    $height: 44px;
    align-self: center;
    margin-top: 30px;
    user-select: none;
    .reg_btn_disable {
      @include common_button_type_2($width: $width, $height: $height);
    }

    .reg_btn_enable {
      @include common_button_type_2_over($width: $width, $height: $height);
      cursor: pointer;
    }
  }
}

.reg_paypal_jsx_m {
  @include flex_div($dir: column) {
    width: 280px;
    // height: 368px;
  }
  padding-top: 16px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 20px;

  .dlg_title {
    @include flex_div($dir: row);
    color: $g-color-primary;
    align-self: center;
    align-items: center;

    img {
      width: 60px;
      height: 20px;
    }
    .txt {
      font-size: 14px;
      font-weight: bold;
      margin-left: 3px;
    }
  }

  .common_section_title {
    @include flex_div($dir: row);
    font-size: 12px;
    margin-bottom: 12px;
    .main {
      font-weight: bold;
    }
    .sub {
      font-weight: normal;
      margin-left: 3px;
    }
  }

  .close_btn {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  // PayPal 이메일
  .section_paypal_email {
    margin-top: 28px;
    $width: 256px;
    $height: 38px;
    .email_input_unfocus {
      @include common_input_box($width: $width, $height: $height, $font-size: 12px);
    }

    .email_input_focus {
      @include common_input_box_focus($width: $width, $height: $height, $font-size: 12px);
    }

    .validation {
      @include common_text($font-size: 10px) {
        color: $g-color-pastelRed;
      }
      margin-top: 10px;
    }
  }

  // 이메일 인증하기
  .section_email_auth_code {
    margin-top: 24px;
    $width: 124px;
    $height: 38px;
    .auth_code_input_unfocus {
      @include common_input_box($width: $width, $height: $height, $font-size: 12px);
    }

    .auth_code_input_focus {
      @include common_input_box_focus($width: $width, $height: $height, $font-size: 12px);
    }

    // 인증 메일 발송 하기 버튼 / 인증하기 버튼
    .send_auth_btn_disable {
      @include common_button_type_2($width: $width, $height: $height, $font-size: 12px);
      margin-left: auto;
    }

    .send_auth_btn_enable {
      @include common_button_type_2_over($width: $width, $height: $height, $font-size: 12px);
      margin-left: auto;
      cursor: pointer;
    }

    // 인증완료
    .auth_complete {
      @include common_button_type_1($width: $width, $height: $height, $font-size: 12px);
      margin-left: auto;
    }
  }

  // 인증메일 안내
  .section_email_auth_guide {
    @include flex_div($dir: column) {
      align-self: stretch;
    }
    .validation {
      margin-top: 6px;
      @include common_text($font-size: 10px) {
        color: $g-color-pastelRed;
      }
    }
    .box {
      margin-top: 6px;
      @include common_gray_box($width: undefined, $height: undefined) {
        align-self: stretch;
        padding: 10px 10px 10px 10px;
        flex-direction: column;
      }
      .txt {
        @include common_text($font-size: 10px) {
          color: $g-color-blueGrey;
          line-height: 1.6;
        }
      }
      .resend_text {
        @include common_text($font-size: 10px) {
          color: $g-color-purpleishBlue;
          cursor: pointer;
          line-height: 1.6;
          font-weight: bold;
        }
      }
    }
  }

  // 신청하기 버튼
  .reg_btn {
    $width: 160px;
    $height: 38px;
    align-self: center;
    margin-top: 20px;
    user-select: none;
    .reg_btn_disable {
      @include common_button_type_2($width: $width, $height: $height, $font-size: 12px);
    }

    .reg_btn_enable {
      @include common_button_type_2_over($width: $width, $height: $height, $font-size: 12px);
      cursor: pointer;
    }
  }
}
