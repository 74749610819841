// default font
$g-font: Spoqa Han Sans JP;

// colors
$g-color-primary: #000;
$g-color-secondary: #00F;
$g-color-bgGrey: #fcfcfc;
$g-color-line: #ebebeb;
$g-color-lineFocus: #d7d7d7;
$g-color-purpleishBlue: #4735ff;
$g-color-blueGrey: #888f99;
$g-color-paleGrey: #e7eaff;
$g-color-paleGrey2: #f9f8fe;
$g-color-paleGrey3: #f7f8ff;
$g-color-black: #121f33;
$g-color-lightGrey: #f5f5f5;
$g-color-veryLightGrey: #bfbfbf;
$g-color-veryLightGrey2: #e8e8e8;
$g-color-klaytn: #50473c;
$g-color-purpleishPink: #ef40c3;
$g-color-cloudyBlue: #c0c4c9;
$g-color-pastelRed: #e65050;
$g-color-dialogBg: rgba;
$g-color-dark: #0d1420;
$g-color-blueBlue: #3322c9;
$g-color-grayBox: #f9f9f9;
$g-color-white: #ffffff;
$g-color-green: #10c738;
$g-color-tomato: #ff4d2f;
$g-color-transparent: rgba(0,0,0,0);

$g-font-size-default: 14px;

// Breakpoints
$breakpoint-mobile: 335px;
$breakpoint-tablet: 758px;
$breakpoint-desktop: 1024px;

