@import "../common";

.yesno_jsx {
  @include flex_div($dir: column) {
    width: 450px;
    align-items: center;
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
  }
  .text {
    @include common_text(16px) {
      font-weight: bold;
      text-align: center;
    }
    margin-bottom: 40px;
  }
  .yesno_btn {
    @include flex_div(row);
    justify-content: space-between;
    .yes_btn {
      @include common_button_type_1(180px, 48px, 14px);
      margin-right: 10px;
      cursor: pointer;
      &:hover {
        border-color: $g-color-purpleishBlue;
      }
    }
    .no_btn {
      @include common_button_type_2_over(180px, 48px, 14px);
      cursor: pointer;
    }
  }
}

.yesno_jsx_m {
}
