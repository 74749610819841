@import "../common";

.cblt_fee_jsx {
  @include flex_div($dir: column) {
    width: 380px;
    // height: 334px;
    align-items: center;
  }
  padding-top: 30px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;

  .title {
    color: $g-color-black;
    font-size: 16px;
    font-weight: bold;
    align-self: center;
    margin-bottom: 40px;
  }

  .img {
    width: 120px;
    height: 28px;
    margin-bottom: 40px;
  }

  .txt1 {
    font-size: 16px;
    font-weight: bold;
    color: $g-color-black;
    margin-bottom: 5px;
    text-align: center;
  }

  .txt2 {
    font-size: 12px;
    color: $g-color-blueGrey;
    margin-bottom: 40px;
    text-align: center;
  }

  .btn {
    @include common_button_type_2_over($width: 300px, $height: 48px);
    cursor: pointer;
  }
}
