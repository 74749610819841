@mixin flex_div($dir) {
  box-sizing: border-box;
  display: flex;
  position: relative;
  flex-direction: $dir;
  @content;
}

@mixin common_text($font-size: $g-font-size-default) {
  font-family: $g-font;
  color: $g-color-black;
  font-size: $font-size;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  white-space: pre-wrap;
  user-select: auto;
  font-size-adjust: none;
  @content;
}

@mixin common_input {
  font-family: $g-font;
  color: $g-color-black;
  border-width: 0px;
  border-radius: 14px;
  background-color: $g-color-transparent;
  -webkit-text-size-adjust: none;
  /* background-color: ${R.colors.devColor1a}; */
  &::placeholder {
    color: $g-color-cloudyBlue;
    font-weight: normal;
  }
  &:focus {
    outline: none;
  }
  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@mixin common_button_type_1($width: 160px, $height: 44px, $font-size: 14px) {
  @include flex_div(column);
  @include common_text($font-size);
  height: $height;
  width: $width;

  justify-content: center;
  align-items: center;
  border-radius: $height / 2;
  border-color: $g-color-line;
  border-width: 1px;
  border-style: solid;
  color: $g-color-purpleishBlue;
  font-weight: bold;
}

@mixin common_button_type_2($width: 160px, $height: 44px, $font-size: 16px) {
  @include flex_div(column);
  @include common_text($font-size);
  height: $height;
  width: $width;

  align-self: center;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background-color: $g-color-paleGrey;
  font-weight: bold;
  color: $g-color-cloudyBlue;
  // cursor: pointer;
  // &:hover {
  //   background-color: $g-color-purpleishBlue;
  //   color: $g-color-white;
  // }
}
@mixin common_button_type_2_over($width: 160px, $height: 44px, $font-size: 16px) {
  @include common_button_type_2($width: $width, $height: $height, $font-size: $font-size);
  background-color: $g-color-purpleishBlue;
  color: $g-color-white;
  &:hover {
    background-color: $g-color-blueBlue;
  }
}

@mixin common_box($width: 100px, $height: 100px) {
  @include flex_div($dir: row);
  width: $width;
  height: $height;
  border-width: 1px;
  border-radius: 15px;
  border-style: solid;
  border-color: $g-color-line;
  background-color: $g-color-bgGrey;
  @content;
}

@mixin common_gray_box($width: 100px, $height: 100px) {
  @include flex_div($dir: row);
  width: $width;
  height: $height;
  border-radius: 15px;
  background-color: $g-color-grayBox;
  @content;
}

@mixin common_input_box($width: 330px, $height: 44px, $font-size: 14px) {
  @include flex_div($dir: column) {
    justify-content: center;
    width: $width;
    height: $height;
  }
  border-radius: 15px;
  border-color: $g-color-line;
  border-width: 1px;
  border-style: solid;
  background-color: $g-color-bgGrey;
  padding-left: 14px;
  padding-right: 14px;

  input {
    @include common_input;
    font-size: $font-size;
    color: $g-color-black;
    font-weight: bold;
  }
}

@mixin common_input_box_focus($width: 330px, $height: 44px, $font-size: 14px) {
  @include flex_div($dir: column) {
    justify-content: center;
    width: $width;
    height: $height;
  }
  border: double 2px transparent;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #6decd6, #4735ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  user-select: none;
  border-radius: 15px;

  input {
    @include common_input;
    margin-left: 14px;
    margin-right: 14px;
    font-size: $font-size;
    color: $g-color-black;
    font-weight: bold;
  }
}

@mixin mobile {
  @media (min-width: #{$breakpoint-mobile}) and (max-width: #{$breakpoint-tablet - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$breakpoint-tablet}) and (max-width: #{$breakpoint-desktop - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$breakpoint-desktop}) {
    @content;
  }
}
